<template>
  <yb-drop-down-item
    class="w-full"
    icon="hierarchy"
    :selected="selected"
    link-class="py-1"
    wrapper-class="yb-view"
    :data-test-id="dataTestId + '-' + option.cluster_name"
    @click="click"
  >
    <div class="yb-view-content truncate text-ellipsis">
      {{ option.cluster_name }}
    </div>
    <yb-icon v-tooltip="'Cluster State: ' + option.state" :icon="getOpIcon(option.state)" class="w-4 h-4 mr-1 ml-4 inline-block yb-view-right" :class="getStatusColorClass(option.state)" />
  </yb-drop-down-item>
</template>

<script>

export default {
  name: 'YbClustersSelectorItem',
  props: {
    option: {
      type: Object
    },
    selected: {
      type: Boolean
    },
    dataTestId: String
  },
  emits: [
    'click'
  ],
  data() {
    return {
    }
  },
  methods: {
    click($event) {
      this.$emit('click', $event)
    },
    getOpIcon,
    getStatusColorClass
  }
}
</script>
